<template>
  <!-- 药品查询 -->
  <div class="drugs">
    <div style="height:14rem">
      <img src="@/static/img/home/drugs/search.png">
    </div>
    <div class="drugs-card">
      <ul>
        <van-form ref="success">
          <li>
            <van-cell-group>
              <van-field
                v-model="value"
                clearable
                label="药品名:"
                placeholder="请输入药品关键词"
                :rules="drugs"
                name="drugs"
              />
            </van-cell-group>
          </li>
          <li>
            <van-field
              readonly
              clickable
              label="有效日期:"
              :value="times"
              placeholder="请选择有效日期"
              @click="showPopup"
            />
            <van-popup
              v-model="show"
              position="bottom"
              :style="{ height: '50%' }"
            >
              <van-datetime-picker
                v-model="currentDate"
                @confirm="confirm"
                @cancel="cancel"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
              />
            </van-popup>
          </li>
        </van-form>
        <li>
          <van-button
            round
            type="info"
            @click="reset"
          >重置</van-button>
          <van-button
            round
            type="info"
            @click="search"
          >搜索</van-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { standard } from "@/common/utils"
export default {
  name: "drugs",
  data () {
    return {
      //输入的药品名称
      value: '',
      //选择的时间
      times: '',
      show: false,
      //日期范围
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2100, 10, 1),
      //当前日期
      currentDate: new Date(),
      //药品名
      drugs: [{
        validator: value => {
          let vali = /\S/.test(value.trim());
          console.log(vali)
          if (!vali) {
            this.$toast('请输入药品关键词')
            this.value = ''
          }
          return vali
        },
        trigger: 'onSubmit'
      }]
    }
  },
  methods: {
    //打开日期
    showPopup () {
      this.show = true;
    },
    //确认
    confirm (value) {
      this.times = standard(value)
      this.show = false;
    },
    //取消
    cancel () {
      this.show = false;
    },
    //重置
    reset () {
      this.value = ''
      this.times = ''
    },
    //搜素
    async search () {
      try {
        await this.$refs.success.validate('drugs')
        this.$router.push('drug-information')
        console.log(this.value.trim(), this.times)
      } catch (err) {
        return err
      }
    }
  },
}
</script>

<style lang="less" scoped>
/deep/.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: 0;
}
.van-cell::after {
  border: 0;
}
img {
  width: 100%;
}
.drugs {
  .drugs-card {
    width: 80vw;
    margin: auto;
    margin-top: 2rem;
    .van-field {
      height: 4rem;
      line-height: 3rem;
      /deep/.van-cell__title {
        font-size: 1rem;
        width: 5rem;
      }
      /deep/ .van-cell__value {
        border: 1px solid @lineColor;
        border-radius: 0.3rem;
        font-size: 1rem;
        padding: 0 0.5rem;
      }
    }
    ul > li:last-child {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      /deep/.van-button {
        width: 5rem;
        margin: auto;
        background-color: @darkColor;
      }
    }
  }
}
</style>